import React, { useState } from "react";

import { Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from "@mui/material";
import { theme } from '../../../themes/default';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RestoreIcon from '@mui/icons-material/Restore';

import { DeleteParticipation } from '../../../pages/admin/participation/helpers/Events'

const SubmissionCard = ({ userId, participationId, title, date, hours, status, academicYear }: any) => {
  const [open, setOpen] = useState(false);

  const deleteParticipation = DeleteParticipation({userId: userId, refetchData: 'user', handleDialog: handleClose, academicYear: academicYear})

  const handleOpen = () => { 
    console.debug('participation id: ' + participationId)
    setOpen(true); 
  };
  
  function handleClose() {
    setOpen(false);
  }
  
  function handleEdit(participationId: String) {
    console.debug('participation id: ' + participationId)
    window.location.href = "/participation/edit/" + participationId
  }

  return (
    <Card sx={{ p: 3 }} >
      <Stack spacing={1.5}>
        {title && <Typography variant="h3">{title}</Typography>}
        {date && <Typography variant="body1"><strong>Date of {date}</strong></Typography>}
        {hours && <Typography variant="h3">{hours} Hours</Typography>}
        {status && <ParticipationStatus status={status} />}
      </Stack>
      {status !== "approved" && 
        <Stack direction="row" spacing={2} sx={{ mt: 6 }}>

          <Button variant="outlined" onClick={(event) => handleEdit(participationId)}>Edit</Button>
          <Button onClick={handleOpen} color="error">Delete</Button>
        </Stack>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" variant="h4" sx={{ width: 400, maxWidth: "70%" }}>
          Delete submission "{title}"?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={(event) => deleteParticipation(participationId)} autoFocus color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default SubmissionCard;

SubmissionCard.defaultProps = {
  title: '',
  date: '',
  hours: '',
  status: ''
};


const ParticipationStatus = ({ status, icon, color }: any) => {
	if (status.toLowerCase() === 'pending') {
    icon = <RestoreIcon />
    color = theme.palette.primary.main
  } else if (status.toLowerCase() === 'denied') {
    icon = <CancelIcon />
    color = theme.palette.error.main
  } else if (status.toLowerCase() === 'approved') {
    icon = <CheckCircleIcon />
    color = theme.palette.success.main
  }
  return (
		<Stack direction="row" spacing={1} alignItems="center" sx={{ color: {color} }}>
      <Typography variant="h4">{status}</Typography>
      {icon}
    </Stack>
	);
};

ParticipationStatus.defaultProps = {
  status: '',
  icon: '',
  color: ''
};