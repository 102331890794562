import { Box, Button, Typography } from "@mui/material";
import React from "react";

import { doLogin } from "../../components/authorization/Utilities";
import { getReturnPage } from "../../components/authorization/LoginLogout";
import LayoutContainer from "../../components/container/Container";
import GlobalNav from "../../components/navigation/GlobalNav";

import welcome from '../../images/acs-welcome.jpeg'

const Home = () => {

  return (
		<>
			<GlobalNav />
			<center>
        <img src={welcome} alt="Welcome to Alumni Scholars Club"/>
      </center>
			<LayoutContainer>
				<Typography variant="h1" mb={4}>Sign-in to the Alumni Scholars Club</Typography>
				<Box mb={4}>
          Welcome Alumni Scholars Program website. This website will allow you to track and manage your service and participation throughout the year. You are required to log in to gain access to the sections of this site that are designed specifically for current Alumni Scholars. In addition to tracking renewal requirements to maintain your scholarship you will also be able to submit notes and tests, as well as access our Notes and Test Bank to help you prepare and study for exams.
          <br/>
          <br/>
          The renewal process is very manageable as long as you stay current.  Every Alumni Scholar is responsible for completing 15 hours of service as well as completing 15 participation credits which are earned by attending Alumni Scholar programming.

          Below is a detailed outline of the expectations:
          <ul>
            <li>Quarterly Meetings –First Week of each Quarter</li>

            <li>In addition you must participate in 12 other events throughout the year under the following categories:</li>
              <ul>
                <li><p><strong>Leadership</strong> (must attend at least 3)</p></li>
                <li><p><strong>Community</strong> (must attend at least 3)</p></li>
                <li><p><strong>Academic/Professional Development</strong> (must attend at least 3)</p></li>
                <li><p>Attend at least 3 more of your choice.</p></li>
              </ul>
              <li><p>15 hours of service during the academic year ( Your Choice)</p></li>
          </ul>
          <i>***Coordinators and those participating in select year-long service programs i.e. LATM, have these 11 events already fulfilled.</i>

          <p>Our Goal is for every Alumni Scholar to renew their scholarship.  We are there to assist you to ensure that happens.  Should you find yourself running into challenges, please come to the Alumni Center to have a conversation sooner rather than later.  The more time we have, the more options we have.</p>

          <p>Good Luck Scholars! Make it an incredible year.</p>
				</Box>
				<Button href={doLogin('signin', getReturnPage("/participation"))} variant="contained" size="large">SIGN IN</Button>
				{/* &nbsp;&nbsp;<a href="/login_demo">LOGIN TEST</a><div></div> */}
				{/* <br/>&nbsp;<br/> */}
				{/* <div>&nbsp;&nbsp;<a href="/file_upload_download_demo">FILE UPLOAD/DOWNLOAD DEMO</a></div> */}
			</LayoutContainer>
		</>
	);
};

export default Home;
