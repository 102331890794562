import { gql } from "@apollo/client";

export const GET_ALL_USERS = gql`
	query getAllUsers($userInputs: [UserInputs]) {
		# query getAllUsers {
		getAllUsers(userInputs: $userInputs) {
			# getAllUsers {
			id
			crm_id
			ucla_id
			full_name
			first_name
			last_name
			scholarship_type
			scholarship_id
			scholarship_name
			graduation_year
			major_degree
			minor_degree
			birthday
			pronoun
			pronoun_other
			email
			preferred_email
			phone
			address_1
			address_2
			city
			state
			postal_code
			country
			permissions
			financial_aid_app_adm_trm
			financial_aid_app_lvl_entry
			user_committee
			# active
			# active_on
			# inactive_on
			last_logged_in
			created_on
			updated_on
			participations {
				id
				academic_year
				participation_category
				participation_status
				name
				description
				participation_date
				participation_hours
				sponsor_supervisor
				sponsor_organization
				sponsor_supervisor_contact_info
				participation_status_changed_on
				created_by_id
				created_on
				updated_on
			}
			course_bank_submissions {
				id
				# userId
				academic_year
				created_by_id
				created_on
				file_id_aws
				file_display_name
				file_path
				# file_name
				file_type
				file_size
				professor_name
				submission_status
				submission_status_changed_by_id
				submission_status_changed_on
				submitted_name
				term
				updated_on
				ucla_classes_class_name
			}
			# current_academic_year
			# participation_total_hours_pending
			# participation_total_hours_denied
			# participation_total_hours_approved
			# participation_total_events_pending
			# participation_total_events_denied
			# participation_total_events_approved
		}
	}
`;

export const GET_ONE_USER = gql`
	query getOneUser($id: ID!, $academic_year: String) {
		getOneUser(id: $id) {
			id
			crm_id
			ucla_id
			full_name
			first_name
			last_name
			full_name
			scholarship_type
			scholarship_id
			scholarship_name
			user_committee
			graduation_year
			major_degree
			minor_degree
			birthday
			pronoun
			pronoun_other
			email
			preferred_email
			phone
			address_1
			address_2
			city
			state
			postal_code
			country
			permissions
			# active
			# active_on
			# inactive_on
			last_logged_in
			created_on
			updated_on
			financial_aid_app_adm_trm
			financial_aid_app_lvl_entry
			participations {
				#May end up removing and calling getUsersParticipations()
				id
				academic_year
				participation_category
				participation_status
				name
				description
				participation_date
				participation_hours
				sponsor_supervisor
				sponsor_organization
				sponsor_supervisor_contact_info
				participation_status_changed_on
				created_by_id
				created_on
				updated_on
			}
			course_bank_submissions {
				id
				# userId
				academic_year
				created_by_id
				created_on
				file_id_aws
				file_display_name
				file_path
				# file_name
				file_type
				file_size
				professor_name
				submission_status
				submission_status_changed_by_id
				submission_status_changed_on
				submitted_name
				term
				updated_on
				ucla_classes_class_name
			}
			# current_academic_year
			# participation_total_hours_pending
			# participation_total_hours_denied
			# participation_total_hours_approved
			# participation_total_events_pending
			# participation_total_events_denied
			# participation_total_events_approved
		}

		getSettingsCurrentAcademicYear {
			id
			academic_year
			academic_year_end
			academic_year_start
			event_participation_goal
			service_hours_goal
			status
		}

		getScholarshipTypes {
			scholarship_types {
				type
				label
			}
		}
		getPronouns {
			pronouns {
				type
				label
			}
		}
		getUserCommittees {
			user_committees {
				type
				label
			}
		}
		getCountries {
			countries {
				code
				label
			}
		}
		getMajors {
			type
			label
		}

		getParticipationCategories {
			participation_categories {
				type
				label
			}
		}
		getAcademicYears {
			academic_years {
				type
				label
				value
			}
		}
		getPermissions {
			permissions {
				type
				label
				value
			}
		}
		getUserParticipationStats(userId: $id, academic_year: $academic_year) {
			userId
			academic_year
			participation_total_events_approved
			participation_total_events_denied
			participation_total_events_pending
			participation_total_hours_approved
			participation_total_hours_denied
			participation_total_hours_pending
		}
	}
`;

export const GET_ONE_USER_NON_ADMIN = gql`
	query getOneUser($id: ID!, $academic_year: String) {
		getOneUser(id: $id) {
			id
			ucla_id
			first_name
			last_name
			full_name
			graduation_year
			major_degree
			minor_degree
			birthday
			pronoun
			pronoun_other
			email
			preferred_email
			phone
			address_1
			address_2
			city
			state
			postal_code
			country
			# active
			# active_on
			# inactive_on
			financial_aid_app_adm_trm
			financial_aid_app_lvl_entry
			user_committee
		}

		getUsersParticipations(userId: $id, academic_year: $academic_year) {
			id
			academic_year
			participation_category
			participation_status
			name
			description
			participation_date
			participation_hours
			sponsor_supervisor
			sponsor_organization
			sponsor_supervisor_contact_info
			participation_status_changed_on
			created_on
			updated_on
			user_first_name
			user_last_name
			# userId
		}

		getUserParticipationStats(userId: $id, academic_year: $academic_year) {
			userId
			academic_year
			participation_total_events_approved
			participation_total_events_denied
			participation_total_events_pending
			participation_total_hours_approved
			participation_total_hours_denied
			participation_total_hours_pending
		}

		getPronouns {
			pronouns {
				type
				label
			}
		}
		getCountries {
			countries {
				code
				label
			}
		}
		# getAcademicYears {
		# 	academic_years {
		# 		type
		# 		label
		# 		value
		# 	}
		# }
		getMajors {
			type
			label
		}
	}
`;

export const POC_USER_GET_PROFILE = gql`
	query getLogInProfile {
		getLogInProfile {
			id
			first_name
			last_name
			email
			profile_filled_out
		}
	}
`;
