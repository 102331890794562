import { useState, useEffect } from "react"

// import { DataGrid, GridColDef, GridToolbar, GridFilterItem } from '@mui/x-data-grid'; //Add GridFilterItem if applying a default filter on init
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { Link } from "@mui/material";

import { formatGQLDate } from '../../../../components/utilities/Formatters';
import { hasData } from '../../../../components/utilities/Validation'

import { GetUsersParticipations } from '../../participation/helpers/LoadData'
import { UpdateParticipationStatus } from '../helpers/Events'
import LoadingSpinner from "../../../../components/progress/Spinner";
import { isCompositeType } from "graphql";

export function BulkParticipationSubmissionList(props: any) {
  const setStatusCount = props.setStatusCount
  const setCheckedParticipations = props.setCheckedParticipations
  // const setParticipationStatus = props.setParticipationStatus
  const participationStatus = props.participation_status ? props.participation_status : 'pending'

  const [ tableData, setTableData ] = useState([]);
  const [ pageSize, setPageSize ] = useState<number>(25);
  const [ dgLoading, setDGLoading ] = useState();

  const response: any = GetUsersParticipations({participation_status: participationStatus, setDGLoading: setDGLoading})
  const participationData: any = response.usersParticipationData
  const refetchQuery: any = response.refetch

  const updateParticipationStatus = UpdateParticipationStatus({refetchData: 'participations', setDGLoading: setDGLoading, refetchQuery: refetchQuery})

  function handleRowSelect (selectedRows: Array<any>) {
    // console.log(selectedRows)
    setCheckedParticipations(selectedRows); //pass back to Parent since Bulk Approve/Deny Buttons are there.

  };

  function handleActionClick (id: string, status: string) {
    // setParticipationStatus(status) //uncomment to force dg to display the list of results based on the status applied
    updateParticipationStatus(id, status)
  }
  //set datagrid data
  useEffect(() => {
    if (hasData(participationData)) {
      console.debug(`BulkParticipationSubmissionList Component: `, participationData)
      setStatusCount(participationData.length)
      setTableData(participationData);
    } else {
      setStatusCount(0)
      setTableData([])
    }
  }, [participationData, setStatusCount, participationStatus ])

  const columns: GridColDef[] = [
    { 
      field: 'actions', 
      headerName: 'Actions', 
      width: 125,
      filterable: false,
      renderCell: (params) =>
        <>
          <Link 
            onClick={(event) => handleActionClick(params.row.id, "approved")} 
            underline="none"
            sx={{ cursor: 'pointer' }}
          >
            Approve
          </Link>
          &nbsp;|&nbsp;
          <Link 
            onClick={(event) => handleActionClick(params.row.id, "denied")} 
            underline="none" 
            color="error"
            sx={{ cursor: 'pointer' }}
          >
            Deny
          </Link>
        </>,
    },
    { field: 'participation_status', headerName: 'Status', width: 90 },
    
    { field: 'academic_year', headerName: 'Academic Year', width: 125, type: 'number', align:'center' },
    // { field: 'participation_status_changed_on', headerName: 'Approved / Denied Date', width: 175, renderCell: (params: any) =>
    // <>
    //   {params.row.participation_status !== 'pending' ? formatGQLDate({gQLDate: params.row.participation_status_changed_on, format: "MM/DD/YYYY"}) : "-"}
    // </> },
    { field: 'participation_category', headerName: 'Type', width: 75 },
    { 
      field: 'name', 
      headerName: 'Participation Title',  
      width: 200,
      renderCell: (params) =>
        <>
          <Link href={`/admin/user/participation/details/${params.row.id}`} underline="none"> {params.row.name}</Link>
        </>,
    },
    { field: 'participation_hours', headerName: 'Hours', width: 75, type: 'number', 
      renderCell: (params) => <>{params.row.participation_category === 'event' ? "-" : params.row.participation_hours }</>
    },
    { field: 'user_last_name', headerName: 'Last, First Name', width: 200, 
      renderCell: (params: any) =>
        <>
          <Link href={`/admin/user/participation/${params.row.userId}`} underline="none">{`${params.row.user_last_name}, ${params.row.user_first_name}`}</Link>
        </> 
    },
    { field: 'sponsor_organization', headerName: 'Sponsor Org', width: 200 },
    { field: 'created_on', headerName: 'Submitted On', width: 175, type: 'date', valueGetter: (params: any) => params.row.created_on ? formatGQLDate({gQLDate: params.row.created_on, format: "MM/DD/YYYY"}) : "-" },
  ];

  return (
    <>
      { dgLoading && 
        <LoadingSpinner />
      }
  
      <DataGrid 
        // checkboxSelection={checkboxSelection}
        autoHeight={true}
        rows={tableData ?? []}
        columns={columns} 
        pageSize={pageSize}
        onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
        rowsPerPageOptions={[2, 5, 10, 25, 100]}                
        density="compact"
        components={{
            Toolbar: GridToolbar,
        }}
        componentsProps={{
          toolbar: {
            csvOptions:{
              fileName: `ASC-Program Participation Submissions-${formatGQLDate({gQLDate: new Date().getTime(), format: "YYYYMMDD"})}`,
            }
          },
        }}
        checkboxSelection
        onSelectionModelChange={(ids) => handleRowSelect(ids)}
        // selectedRows={selectedRows}
        initialState={{
          // filter: {
          //   filterModel: {
          //     items: [{ columnField: 'participation_status', operator: 'equals', value: 'pending' } as GridFilterItem,],
          //   },
          // },
          sorting: {
              sortModel: [{ field: 'created_on', sort: 'asc' }],
          },
          columns: {
            columnVisibilityModel: {
              scholarship_name: false,
              // last_logged_in: false,
              updated_on: false,
              // created_on: false
            }
          }
        }}
        // onRowClick={handleOnCellClick}
    />
    </>
  )
}

export default BulkParticipationSubmissionList