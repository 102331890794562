import React, { useState, useEffect } from 'react'

import { useQuery } from '@apollo/client';

import { Link } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import LoadingSpinner from "../../../../components/progress/Spinner";

import { formatGQLDate } from '../../../../components/utilities/Formatters';

import { GET_ALL_USERS } from '../../../../Graphql/Users/QueryUser';

const columns: GridColDef[] = [
  { 
    field: 'actions', 
    headerName: 'Actions', 
    width: 175,
    renderCell: (params) =>
      <>
        <Link href={`/admin/user/participation/${params.row.id}`} underline="none">Participation</Link>
        &nbsp;|&nbsp;
        <Link href={`/admin/user/${params.row.id}`} underline="none">Profile</Link>
      </>,
  },
  
  { field: 'id', headerName: 'ASC DB ID', width: 250 },
  { field: 'crm_id', headerName: 'CRM ID', width: 150 },
  { field: 'ucla_id', headerName: 'Bruin ID', minWidth: 10},
  { field: 'last_name', headerName: 'Last', width: 150 },
  { field: 'first_name', headerName: 'First', width: 150 },
  { field: 'email', headerName: 'Email', width: 200 },
  { field: 'scholarship_type', headerName: 'Scholarship Type', width: 150 },
  { field: 'scholarship_name', headerName: 'Scholarship Name', width: 150 },
  { field: 'financial_aid_app_adm_trm', headerName: 'Entered', width: 70 },
  { field: 'financial_aid_app_lvl_entry', headerName: 'Entered As', width: 90 },
  { field: 'graduation_year', headerName: 'Grad Yr', width: 70, type: 'number' },
  { field: 'active', headerName: 'Active', width: 75 },
  { field: 'last_logged_in', headerName: 'Last Login', width: 175, type: 'date',
    // valueFormatter affects the value in an export.  eg) without this, date will display as an epoch timestamp
    valueFormatter: ({value}) => `${value ? formatGQLDate({gQLDate: value, format: "MM/DD/YYYY"}) : "-"}`
  },
  { field: 'updated_on', headerName: 'Updated', width: 150, type: 'date', valueGetter: (params: any) => params.row.updated_on ? formatGQLDate({gQLDate: params.row.updated_on, format: "MM/DD/YYYY"}) : "-" },
  { field: 'created_on', headerName: 'Created', width: 100, type: 'date', valueGetter: (params: any) => params.row.created_on ? formatGQLDate({gQLDate: params.row.created_on, format: "MM/DD/YYYY"}) : "-" },
  {
    field: 'permissions',
    headerName: 'Access Rights',
    width: 275,
    valueFormatter: ({ value }) => {
      const permissions: { [key: string]: string } = {
        adminPermanent: 'NE',
        adminSettings: 'S',
        adminUserManagement: 'UM',
        adminParticipationManagement: 'PPM',
        adminCourseBankManagement: 'TBM',
        adminMyLastLecture: 'MLL',
        userBankNoAccess: 'TBA-B',
        userBankAllowView: 'TBA-A',
      };
      const permissionList = value ? value.split(',').map((permission: string) => permissions[permission]).filter(Boolean) : [];
      return permissionList.join(', ');
    },
    renderCell: (params: any) => {
      const permissions: { [key: string]: string } = {
        adminPermanent: 'NE',
        adminSettings: 'S',
        adminUserManagement: 'UM',
        adminParticipationManagement: 'PPM',
        adminCourseBankManagement: 'TBM',
        adminMyLastLecture: 'MLL',
        userBankNoAccess: 'TBA-B',
        userBankAllowView: 'TBA-A',
      };
      const permissionList = params.row.permissions ? params.row.permissions.split(',').map((permission: string) => permissions[permission]).filter(Boolean) : [];
      return (
        <>
          {permissionList.join(', ')}
        </>
      );
    }
  }

];

export function ListOfUsers(props:any) {
    const { updateActiveUserCount } = props
    const { loading: loadingUsers, error: errorUsers, data: dataUsers } = useQuery(GET_ALL_USERS, { fetchPolicy: "network-only" })

    const [ tableData, setTableData ] = useState([]);
    const [ pageSize, setPageSize ] = useState<number>(25);

    // const handleOnCellClick = (params: any) => {
    //   // console.debug('data grid cell clicked params:');
    //   // console.debug(params);
    //   window.location.href = `/admin/user/${params.row.id}`
    // };
    const countUsers = (users: any) => {
      let countActive = 0;
      let countInactive = 0;
      users.forEach((user: any) => {
        if (user.last_logged_in) {
          countActive++;
        } else {
          countInactive++;
        }
      });
      const response = {
        active_count: countActive,
        inactive_count: countInactive,
      };
      return response;
    };

    useEffect(() => {
        if (errorUsers) {
          console.debug('Error Users:', errorUsers)
        }
        if (dataUsers) {
            console.debug('Update DataGrid - List of Users');
            console.debug(dataUsers.getAllUsers);
            // Call back to update the Active User Count
            // Add method to just get active users
            const updateActiveCount = updateActiveUserCount ? updateActiveUserCount : () => console.log('callback updateActiveUserCount() does not exist')
            const userCounts = countUsers(dataUsers.getAllUsers)
            updateActiveCount(userCounts.active_count, userCounts.inactive_count)

            
            setTableData(dataUsers.getAllUsers);
        }

    }, [loadingUsers, errorUsers, dataUsers, updateActiveUserCount]);

  return (
      <>
        { loadingUsers && 
          <LoadingSpinner/>
        }
        <div className="container data-grid-users">
            <h4>Users</h4>
            <DataGrid 
              // checkboxSelection={checkboxSelection}
              autoHeight={true}
              rows={tableData}
              columns={columns} 
              pageSize={pageSize}
              onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
              rowsPerPageOptions={[2, 5, 10, 25, 100]}                
              density="compact"
              components={{
                  Toolbar: GridToolbar,
              }}
              componentsProps={{
                toolbar: {
                  csvOptions:{
                    fileName: `ASC-Users-${formatGQLDate({gQLDate: new Date().getTime(), format: "YYYYMMDD"})}`,
                  }
                },
              }}
              initialState={{
                sorting: {
                    sortModel: [{ field: 'last_name', sort: 'asc' }],
                },
                columns: {
                  columnVisibilityModel: {
                    id: false,
                    crm_id: false,
                    active: false,
                    scholarship_type: false,
                    financial_aid_app_adm_trm: true,
                    financial_aid_app_lvl_entry: true,
                    // scholarship_name: false,
                    // last_logged_in: false,
                    updated_on: false,
                    created_on: true
                  }
                }
              }}
              // onRowClick={handleOnCellClick}
            />
        </div>
      </>
  )
}

export default ListOfUsers