import { Box, Button, Stack, Typography } from "@mui/material";
import LayoutContainer from "../../../components/container/Container";
import SubmissionCard from "./SubmissionCard";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LinkButton from "../../../components/button/Button";

import { formatGQLDate } from "../../../components/utilities/Formatters"

const ParticipationSubmissions = (props: any) => {
  // const records_per_page = 20
  const userID = props.id
  const participations: any[] = props.participationData ? props.participationData.sort((a: any, b: any) => b.created_on.localeCompare(a.created_on)) : [] //result => desc order 
  const academicYear: string = props.academicYearSettings.academic_year

	return (
    <LayoutContainer background="light">
      <Typography variant="h3" mb={3}>Program Participation Submissions</Typography>
      <LinkButton to={`/participation/new/${userID}`} variant="contained" type="add">Submit Event or Service Participation</LinkButton>
      { participations.length === 0 ? (
          <Typography variant="h4" mt={3}>
            You do not have any current participation submissions. Please use the button above to submit new program participation.
          </Typography>
        ) : <></>
      }
      
      <Box mt={3} sx={{ maxWidth: 540 }}>
        <Stack spacing={4}>
          {participations.map((participation: any, index: number) => {
            const id = participation.id ? participation.id : ""
            const name = participation.name ? participation.name : ""
            const date = participation.participation_date ? formatGQLDate({gQLDate: participation.participation_date, format: "MM/DD/YYYY"}) : ""
            const category = participation.participation_category ? participation.participation_category : ""
            const status = participation.participation_status ? participation.participation_status : ""
            const hours = participation.participation_hours ? participation.participation_hours : 0
            
            if (category === 'event') { 
              return <SubmissionCard key={index} userId={userID} participationId={id} title={name} date={`Event: ${date}`} status={status} academicYear={academicYear} />
            } else if (category === 'service') {
              return <SubmissionCard key={index} userId={userID} participationId={id} title={name} date={`Service: ${date}`} hours={hours} status={status} academicYear={academicYear} />
            }
            return <></>
          })}

          {/* { participations.length >  records_per_page ? (
            <Box>
              <Button variant="outlined" size="large" startIcon={<ArrowDropDownIcon />}>Show More</Button>
            </Box>
          ) : <></>
          }  */}
        </Stack>
      </Box>
    </LayoutContainer>
	);
};

export default ParticipationSubmissions
